import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Grid, Loader } from 'semantic-ui-react';
import CCP from './components/CCP';
import Metrics from './components/Metrics';
import './App.css';
// import logo from './logo.svg';

interface IAuthState {
  signedIn: boolean;
  user: CognitoUser | null;
}

const App: React.FC = () => {
  const [auth, setAuth] = useState<IAuthState>({ signedIn: false, user: null });

  useEffect(() => {
    let redirect: any;

    Hub.listen('auth', (data: any) => {
      switch (data.payload.event) {
        case 'signIn':
          clearTimeout(redirect);
          setAuth({ signedIn: true, user: data.payload.data });
          break;
        default:
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then((user: CognitoUser) => setAuth({ signedIn: true, user }))
      .catch(() => {
        redirect = setTimeout(
          () => {
            if (!auth.signedIn) {
              console.log('Not signed in');
              Auth.federatedSignIn();
            }
          },
          window.location.search.includes('code') ? 5000 : 500
        );
      });
    // eslint-disable-next-line
  }, []);

  if (!auth.signedIn) return <Loader active size="large" />;

  return (
    <div>
      <Grid>
        <Grid.Row>
          <CCP />
          <Metrics />
        </Grid.Row>
      </Grid>
    </div>
  );
};

export default App;
