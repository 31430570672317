import React from 'react';
import { Table } from 'semantic-ui-react';
import { IQueue } from '../interfaces';

interface IProps {
  queues: IQueue[];
}

const QueueMetrics = ({ queues }: IProps): JSX.Element => {
  function renderRows(queues: IQueue[]): JSX.Element[] {
    return queues.map((queue: IQueue, i: number) => {
      return (
        <Table.Row key={i}>
          <Table.Cell>{queue.Queue}</Table.Cell>
          <Table.Cell>{queue.ContactsQueued}</Table.Cell>
          {/* WaitTime is in milliseconds */}
          <Table.Cell>
            {new Date(parseInt(queue.WaitTime)).toISOString().substr(11, 8)}
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Queue</Table.HeaderCell>
          <Table.HeaderCell>Contacts Waiting</Table.HeaderCell>
          <Table.HeaderCell>Wait Time</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderRows(queues)}</Table.Body>
    </Table>
  );
};

export default QueueMetrics;
