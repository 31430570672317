import React, { useEffect } from 'react';
import config from '../config';

declare const window: any;

const CCP: React.FC = () => {
  const containerID: string = 'ccpContainer';

  useEffect(() => {
    const connect = window.connect;
    const {
      connect: { CCP_URL, REGION, SAML_LOGIN_URL }
    } = config;

    if (!connect.core.initialized) {
      console.log('Connect initialization started', CCP_URL);
      connect.core.initCCP(document.getElementById(containerID), {
        ccpUrl: CCP_URL,
        region: REGION,
        loginUrl: SAML_LOGIN_URL,
        loginPopup: true,
        loginPopupAutoClose: true,
        softphone: {
          allowFramedSoftphone: true
        }
      });
    } else {
      console.log('Connect Already Initialized!');
    }
  }, []);

  return (
    <div>
      <div id={containerID}></div>
      <div id="block-clear-contact" style={{ display: 'none' }}></div>
    </div>
  );
};

export default CCP;
