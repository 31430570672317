// config file built according to format here https://serverless-stack.com/chapters/manage-environments-in-create-react-app.html

const redirect = (url: string) => {
  if (process.env.NODE_ENV === 'development') {
    return 'http://localhost:3000';
  }
  return url;
};

// frontend stage is determined by process.env.NODE_ENV and defaults to 'development' unless npm run build command is executed (then it's 'production')
// AWS backend variables listed here will be determined based on backend stage which will be controlled manually by environment variable REACT_APP_BACKEND_STAGE (defaults to 'dev')

const dev = {
  apiGateway: {
    REGION: 'us-west-2',
    WSS_URL: 'wss://3fuopisubg.execute-api.us-west-2.amazonaws.com/dev',
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_ryaAxeZAU',
    APP_CLIENT_ID: '4ge12tjh7aui231jjd2v9rva3v',
    IDENTITY_POOL_ID: 'us-west-2:50ba0b81-8d4d-4f40-9ba1-dcfb77c297ff',
    DOMAIN:
      'university-of-arizona-ccp-auth-domain-dev.auth.us-west-2.amazoncognito.com',
    SCOPE: ['email', 'openid', 'profile'],
    REDIRECT_SIGN_IN: redirect('https://d880ze18s72fh.cloudfront.net'),
    REDIRECT_SIGN_OUT: redirect('https://d880ze18s72fh.cloudfront.net'),
    RESPONSE_TYPE: 'code', // 'code' or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  connect: {
    REGION: 'us-west-2',
    CCP_URL: 'https://dev-uarizona-connect.my.connect.aws/ccp-v2/',
    SAML_LOGIN_URL:
      'https://shibboleth.arizona.edu/idp/profile/SAML2/Unsolicited/SSO?providerId=urn:amazon:webservices&target=https://us-west-2.console.aws.amazon.com/connect/federate/4ab94f10-794a-44ac-96d7-14a3344a37d3%3Fnew_domain%3Dtrue',
  },
};

const prod = {
  apiGateway: {
    REGION: 'us-west-2',
    WSS_URL: 'wss://1dcrwomnch.execute-api.us-west-2.amazonaws.com/prod',
  },
  cognito: {
    REGION: 'us-west-2',
    USER_POOL_ID: 'us-west-2_wn3W8BpEN',
    APP_CLIENT_ID: '648gct2am920ah1sofrvot7rpv',
    IDENTITY_POOL_ID: 'us-west-2:cc2ee984-00cf-4d97-b2c8-47c7467a6c68',
    DOMAIN:
      'university-of-arizona-ccp-auth-domain-prod.auth.us-west-2.amazoncognito.com',
    SCOPE: ['email', 'openid', 'profile'], // oauth scopes
    REDIRECT_SIGN_IN: redirect('https://d3pmav5jzcgdzk.cloudfront.net'), // URL of the app/cloudfront-distribution should go here
    REDIRECT_SIGN_OUT: redirect('https://d3pmav5jzcgdzk.cloudfront.net'), // Same as above unless there is a specific logout route
    RESPONSE_TYPE: 'code', // 'code' or 'token', note that REFRESH token will only be generated when the responseType is code
  },
  connect: {
    REGION: 'us-west-2',
    CCP_URL: 'https://arizona-connect.my.connect.aws/ccp-v2/',
    SAML_LOGIN_URL:
      'https://shibboleth.arizona.edu/idp/profile/SAML2/Unsolicited/SSO?providerId=urn:amazon:webservices&target=https://us-west-2.console.aws.amazon.com/connect/federate/7ab54bba-5760-4850-90c0-fab2aa7dc776%3Fnew_domain%3Dtrue',
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_BACKEND_STAGE === 'prod' ? prod : dev;

export default {
  // Add common config values here
  // MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
