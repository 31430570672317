import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import config from './config';
import 'semantic-ui-css/semantic.min.css';
// import "./index.css";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
    oauth: {
      domain: config.cognito.DOMAIN,
      scope: config.cognito.SCOPE,
      redirectSignIn: config.cognito.REDIRECT_SIGN_IN,
      redirectSignOut: config.cognito.REDIRECT_SIGN_OUT,
      responseType: config.cognito.RESPONSE_TYPE
    }
  }
});

ReactDOM.render(<App />, document.getElementById('root'));
