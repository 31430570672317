import React from 'react';
import { Table } from 'semantic-ui-react';
import { IAgent } from '../interfaces';

interface IProps {
  agents: IAgent[];
}

const PeerStatus = ({ agents }: IProps): JSX.Element => {
  function renderRows(agents: IAgent[]): JSX.Element[] {
    return agents.map((agent: IAgent, i: number) => {
      return (
        <Table.Row key={i}>
          <Table.Cell>
            {agent.FirstName} {agent.LastName}
          </Table.Cell>
          <Table.Cell>
            {agent.Status.charAt(0).toUpperCase() +
              agent.Status.slice(1).toLowerCase()}
          </Table.Cell>
          <Table.Cell>
            {['CONNECTED', 'CONNECTING'].includes(agent.Status.toUpperCase())
              ? agent.Queue
              : ''}
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  return (
    <Table>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Active Queue</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderRows(agents)}</Table.Body>
    </Table>
  );
};

export default PeerStatus;
