import React from 'react';
import { Table, Segment } from 'semantic-ui-react';
import { IContact } from '../interfaces';

interface IProps {
  contact: IContact | {};
}

const ContactDetails = ({ contact }: IProps): JSX.Element => {
  function renderRows(contact: IContact | any): any {
    return Object.keys(contact).map((attr, i) => {
      return (
        <Table.Row key={i}>
          <Table.Cell>{attr}</Table.Cell>
          <Table.Cell>{contact[attr]}</Table.Cell>
        </Table.Row>
      );
    });
  }

  if (!Object.keys(contact).length) return <Segment>No Active Contact</Segment>;

  return (
    <Table basic="very">
      <Table.Body>{renderRows(contact)}</Table.Body>
    </Table>
  );
};

export default ContactDetails;
