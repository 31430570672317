import React, { useState, useEffect, useRef } from 'react';
import { Accordion, AccordionTitleProps } from 'semantic-ui-react';
import ContactDetails from './ContactDetails';
import CallLog from './CallLog';
import QueueMetrics from './QueueMetrics';
import PeerStatus from './PeerStatus';
import { IAgent, ICall, IQueue, IContact, IUser } from '../interfaces';
import { Auth } from 'aws-amplify';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import config from '../config';
import { PostData, PostAgentStatus, PostCallLog, PostQueueMetric } from '../../../../backend/src/models/PostData';

declare const window: any;

const Metrics: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState<number>(3);
  const [contact, setContact] = useState<IContact | {}>({});
  const [callLog, setCallLog] = useState<ICall[]>([]);
  const [queues, setQueues] = useState<IQueue[]>([]);
  const [agents, setAgents] = useState<IAgent[]>([]);
  const [token, setToken] = useState<string>('');
  const [user, setUser] = useState<IUser>({
    name: '',
    username: '',
    routingProfile: '',
    queues: [],
  });
  const [retries, setRetries] = useState<number>(0);

  const ws = useRef<WebSocket | any>();
  const uiBlockerID: string = 'block-clear-contact';

  //refreshTokens
  useEffect(() => {
    refreshTokens();
  }, []);

  //refreshUser if retries > 30
  //start WebSocketService
  useEffect(() => {
    if (!user.username || !token) return;

    if (retries > 30) {
      refreshUser();
      return;
    }

    startWSS(user.username, token);

    return () => {
      ws.current.close();
      console.log('Closed connection');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.username, retries]);

  //handleResponse
  //event data formats sent through websocket can be found in backend/src/lambdas/Websocket*
  useEffect(() => {
    if (ws.current) {
      ws.current.onmessage = (event: MessageEvent) => {
        const response = JSON.parse(event.data);
        console.log("response ", JSON.stringify(response));

        if (response.init) {
          handleInit(response);
        } else {
          handleResponse(response);
        }

      };
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callLog, agents, queues, user, retries]);

  //subscribeConnectEvents
  useEffect(() => {
    subscribeConnectEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshUser = () => {
    console.log('Refreshing page for user');
    window.location.reload();
  };

  const refreshTokens = () => {
    console.log('Refreshing user access tokens');
    Auth.currentSession().then((session: CognitoUserSession) => {
      setToken(session.getAccessToken().getJwtToken());
    });
  };

  const startWSS = (username: string, token: string) => {
    const subscribe = { action: 'init' };
    const params = {
      AgentUsername: username,
      token,
    };

    ws.current = new WebSocket(
      [config.apiGateway.WSS_URL, new URLSearchParams(params).toString()].join(
        '?'
      )
    );

    ws.current.onopen = () => {
      ws.current.send(JSON.stringify(subscribe));
      console.log('Subscribing to WSS');
    };

    ws.current.onclose = () => {
      console.log('WSS has lost connection.');
      ws.current.close();

      refreshTokens();
      setTimeout(() => setRetries(retries + 1), 20000);
    };

    ws.current.onerror = () => {
      console.log('There was an error');
    };

    window.WBS = ws.current;
  };

  const handleInit = (response: MessageEvent['data']) => {
    switch (response.Table) {
      case 'QueueMetrics':
        setQueues(response.Items.filter(isRelevantQueue));
        break;
      case 'AgentMetrics':
        !user.department
          ? findMyDepartmentFirst(response.Items)
          : setAgents(noOfflineAgents(response.Items.filter(isRelevantAgent)));
        break;
      case 'AgentCallLog':
        setCallLog(dateSort(response.Items));
        break;
      default:
        console.log('Unrecognized message from websocket', response);
    }
  }

  const handleResponse = (response: PostData) => {
    const queueMetrics: PostQueueMetric[] = [];
    const agentMetrics: PostAgentStatus[] = [];
    const agentCallLog: PostCallLog[] = [];

    response.records.forEach(record => {
      switch (record.Table) {
        case 'QueueMetrics':
          queueMetrics.push(record as PostQueueMetric);
          break;
        case 'AgentMetrics':
          agentMetrics.push(record as PostAgentStatus);
          break;
        case 'AgentCallLog':
          agentCallLog.push(record as PostCallLog);
          break;
        default:
          console.log('Unrecognized record from websocket', JSON.stringify(response));
      }
    })

    if (queueMetrics.length > 0) {
      handleQueues(queueMetrics);
    }

    if (agentMetrics.length > 0) {
      handleAgents(agentMetrics);
    }

    if (agentCallLog.length > 0) {
      handleCallLog(agentCallLog);
    }
  };

  const handleQueues = (queueResponse: PostQueueMetric[]) => {
    const queueData: IQueue[] = queueResponse.filter(isRelevantQueue);
    if (queueData.length > 0) {
      updateQueues(queueData);
    }
  };

  const isRelevantQueue = (queue: PostQueueMetric) => {
    return user.queues.includes(queue.Queue);
  };

  const updateQueues = (queueData: IQueue[]) => {
    let oldQueues: IQueue [] = queues.slice();
    const newQueues: IQueue[] = [];
    queueData.forEach(queue => {
      const index = oldQueues.findIndex(
        (oldQueue: IQueue) => oldQueue.Queue === queue.Queue
      );

      if (index === -1) {
        newQueues.push(queue);
      } else {
        oldQueues = replaceAndReturn(oldQueues, index, queue);
      };
    });

    setQueues([...newQueues, ...oldQueues]);
  }

  const handleAgents = (agentResponse: PostAgentStatus[]) => {
    const agentData: IAgent[] = agentResponse.filter(isRelevantAgent);
    if (agentData.length > 0) {
      updateAgents(agentData);
    }
  };

  const isRelevantAgent = (agent: PostAgentStatus) => {
    return (
      user.routingProfile === agent.RoutingProfile ||
      user.department === getAgentDepartment(agent)
    );
  };

  const updateAgents = (agentData: IAgent[]) => {
    let oldAgentsState: IAgent[] = agents.slice();
    const newAgentsState: IAgent[] = [];

    agentData.forEach((agent) => {
      const index = oldAgentsState.findIndex(
        (oldAgentsState: IAgent) => oldAgentsState.AgentUsername === agent.AgentUsername
      );

      if (index === -1) {
        newAgentsState.push(agent);
      } else {
        oldAgentsState = replaceAndReturn(oldAgentsState, index, agent)
      };
    });

    setAgents([...noOfflineAgents(newAgentsState), ...noOfflineAgents(oldAgentsState)]);
  }

  const noOfflineAgents = (agents: IAgent[]): IAgent[] => {
    return agents.filter((agent: IAgent) => isOnlineAgent(agent));
  };

  const isOnlineAgent = (agent: IAgent) => {
    return agent.Status !== 'Offline';
  };

  const replaceAndReturn = (
    array: any[],
    index: number,
    newValue: any
  ): any[] => {
    const newArray = array.slice();
    newArray.splice(index, 1, newValue);
    return newArray;
  };

  const handleCallLog = (callLogResponse: PostCallLog[]) => {
    const callLogData: ICall[] = callLogResponse.filter(isRelevantCallLog);
    const newCallLogs: ICall[] = [...callLogData, ...callLog];
    setCallLog(dateSort(newCallLogs));
  };

  const isRelevantCallLog = (callLog: PostCallLog) => {
    return (user.username === callLog.AgentUsername);
  }

  const dateSort = (calls: ICall[]): ICall[] => {
    return calls.slice().sort((callA: ICall, callB: ICall): number => {
      return new Date(callA.ConnectedTimestamp) <
        new Date(callB.ConnectedTimestamp)
        ? 1
        : -1;
    });
  };

  const findMyDepartmentFirst = (agents: PostAgentStatus[]) => {
    let myDepartment: string;
    // loop once through agents to find my agent profile and department
    agents.forEach((agent: PostAgentStatus) => {
      if (agent.AgentUsername === user.username) {
        // once user agent data found, set user's department and filter relevant agents
        myDepartment = getAgentDepartment(agent);
        setUser({ ...user, department: myDepartment });
        setAgents(
          noOfflineAgents(
            agents.filter((agent: PostAgentStatus) => {
              return (
                user.routingProfile === agent.RoutingProfile ||
                myDepartment === getAgentDepartment(agent)
              );
            })
          )
        );
        return; // return early
      }
    });
  };

  const getAgentDepartment = (agent: PostAgentStatus): string => {
    return [agent.HierarchyLevel1, agent.HierarchyLevel2].join('/');
  };

  const subscribeConnectEvents = () => {
    if (window.connect.core.initialized) {
      subscribeContactEvents();
      subscribeAgentEvents();
    } else {
      console.log('Connect not yet initialized, waiting to subscribe events');
      setTimeout(subscribeConnectEvents, 1000);
    }
  };

  const subscribeContactEvents = () => {
    console.log('Subscribing to Connect Contact Events');
    window.connect.contact((contact: any) => {
      contact.onConnecting((contact: any) => {
        console.log('Connecting to contact:', contact.contactId);

        const contactAttributes = contact.getAttributes();
        Object.keys(contactAttributes).forEach((key) => {
          contactAttributes[key] = contactAttributes[key].value;
        });
        contactAttributes.Phone = contact
          .getActiveInitialConnection()
          .getEndpoint().phoneNumber;
        console.log(JSON.stringify(contactAttributes));

        setContact(contactAttributes);
      });

      contact.onEnded(() => {
        console.log('Call ended', contact.contactId);
        setContact({});
      });
    });
  };

  const subscribeAgentEvents = () => {
    console.log('Subscribing to Connect Agent Events');
    window.connect.agent((agent: any) => {
      const agentConfig = agent.getConfiguration();
      const userAgentDetails: IUser = {
        name: agentConfig.name,
        username: agentConfig.username,
        routingProfile: agentConfig.routingProfile.name,
        queues: agentConfig.routingProfile.queues.map(
          (queue: any) => queue.name
        ),
      };

      setUser(userAgentDetails);

      agent.onAfterCallWork((agent: any) => {
        window.document.getElementById(uiBlockerID).style = undefined;
        console.log(
          'On after call work, blocking "clear contact" for 3 seconds'
        );
        setTimeout(() => {
          window.document.getElementById(uiBlockerID).style.display = 'none';
        }, 3000);
      });
    });
  };

  function handleClick(
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    data: AccordionTitleProps
  ): void {
    const { index } = data;

    setActiveIndex(Number(index));
  }
  return (
    <Accordion styled>
      <Accordion.Title
        active={activeIndex === 0}
        index={0}
        onClick={handleClick}
      >
        Contact Details
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 0}>
        <ContactDetails contact={contact} />
      </Accordion.Content>

      <Accordion.Title
        active={activeIndex === 1}
        index={1}
        onClick={handleClick}
      >
        Call Log ({callLog.length})
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 1}>
        <CallLog callLog={callLog} />
      </Accordion.Content>

      <Accordion.Title
        active={activeIndex === 2}
        index={2}
        onClick={handleClick}
      >
        Queue Metrics ({queues.length})
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 2}>
        <QueueMetrics queues={queues} />
      </Accordion.Content>
      <Accordion.Title
        active={activeIndex === 3}
        index={3}
        onClick={handleClick}
      >
        Peer Status ({agents.length})
      </Accordion.Title>
      <Accordion.Content active={activeIndex === 3}>
        <PeerStatus agents={agents} />
      </Accordion.Content>
    </Accordion>
  );
};

export default Metrics;
